
import { Component, Vue } from "vue-property-decorator";
import { Validations } from "vuelidate-property-decorators";
import { required, email } from "vuelidate/lib/validators";

@Component
export default class MixinShopSettingEditorValidator extends Vue {
  @Validations()
  validations = {
    item: {
      //名称
      shopName: { required },
      //連絡先住所
      contactAddress: { required },
      //連絡先メールアドレス
      contactEmailAddress: { required, email },
      //個人情報保護方針
      privacyPolicy: { required },
      //特定商取引に基づく表記
      specifiedCommercialTransactionAct: { required },
    },
  };
  //---------------------------
  // methods
  //---------------------------

  /**
   * 名称入力エラーメッセージを返します.
   */
  get shopNameErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.shopName?.$dirty) return errors;
    !this.$v.item.shopName?.required && errors.push("名称を入力してください.");
    return errors;
  }

  /**
   * 連絡先住所入力エラーメッセージを返します.
   */
  get contactAddressErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.contactAddress?.$dirty) return errors;
    !this.$v.item.contactAddress?.required &&
      errors.push("連絡先住所を入力してください.");
    return errors;
  }

  /**
   * 連絡先メールアドレス入力エラーメッセージを返します.
   */
  get contactEmailAddressErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.contactEmailAddress?.$dirty) return errors;
    !this.$v.item.contactEmailAddress?.required &&
      errors.push("連絡先メールアドレスを入力してください.");
    !this.$v.item.contactEmailAddress?.email &&
      errors.push("メールアドレス形式で入力してください.");
    return errors;
  }

  /**
   * 個人情報保護方針入力エラーメッセージを返します.
   */
  get privacyPolicyErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.privacyPolicy?.$dirty) return errors;
    !this.$v.item.privacyPolicy?.required &&
      errors.push("個人情報保護方針を入力してください.");
    return errors;
  }

  /**
   * 特定商取引に基づく表記入力エラーメッセージを返します.
   */
  get specifiedCommercialTransactionActErrors(): string[] {
    const errors: string[] = [];
    if (!this.$v.item.specifiedCommercialTransactionAct?.$dirty) return errors;
    !this.$v.item.specifiedCommercialTransactionAct?.required &&
      errors.push("特定商取引に基づく表記を入力してください.");
    return errors;
  }
}
