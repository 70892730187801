





































































































import { ShopSetting } from "@/graphql/client";
import shopSettingService from "@/service/shopSettingService";
import { Component, Ref, Mixins } from "vue-property-decorator";
import ProgressLinear from "@/components/molecules/ProgressLinear.vue";
import MixinValidator from "@/components/mixins/shop_setting/MixinShopSettingEditorValidator.vue";
import MixinFormatter from "@/components/mixins/MixinFormatter.vue";
import { NotificationState } from "@/store/modules/notification";

@Component({ components: { ProgressLinear } })
export default class ShopSettingEditor extends Mixins(
  MixinFormatter,
  MixinValidator
) {
  @Ref() readonly editForm!: HTMLFormElement;
  //---------------------------
  // data
  //---------------------------
  item: ShopSetting = shopSettingService.defaultShop;
  isProgressing = false;
  //---------------------------
  // mounted
  //---------------------------
  mounted(): void {
    this.fetchData();
  }
  //---------------------------
  // computed
  //---------------------------
  //---------------------------
  // methods
  //---------------------------
  async fetchData(): Promise<void> {
    shopSettingService.getShopSetting().then((item) => {
      this.item = item;
    });
  }
  /**
   * 入力内容を保存します.
   */
  save(): void {
    this.$v.$touch();
    if (!this.$v.$invalid) {
      this.isProgressing = true;
      //update
      shopSettingService
        .updateShopSetting(this.item)
        .then(() => {
          this.isProgressing = false;
          NotificationState.notifySuccess("設定を更新しました!");
        })
        .catch((err) => {
          console.log(err);
          NotificationState.notifyError("設定の更新ができませんでした.");
        });
    }
  }
}
