










import SubPageTemplate from "@/components/templates/SubPageTemplate.vue";
import ShopSettingEditor from "@/components/organisms/shop_setting/ShopSettingEditor.vue";

export default {
  components: {
    SubPageTemplate,
    ShopSettingEditor,
  },
};
